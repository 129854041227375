import { gql } from '@apollo/client';
import { setLastViewedProperty } from '@src/auth/localStorageAuth';
import { PropertyListFragment, useAllPropertiesQuery } from '@src/graphql';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePersistOptions } from './usePersistOptions';

export const PropertyListFragmentDocument = gql(`
  fragment PropertyList on PropertySummaryModel {
    id
    name
  }
`);

export const PropertiesDocument = gql(`
	query AllProperties {
    properties {
			getAll {
        id
        name
			}
		}
	}
`);

type UsePropertiesReturn = {
  properties: PropertyListFragment[];
  setCurrentProperty: (propertyId: string) => void;
} & Pick<ReturnType<typeof useAllPropertiesQuery>, 'loading' | 'error'>;

export function useProperties(): UsePropertiesReturn {
  const { loadOptions } = usePersistOptions();
  const navigate = useNavigate();
  const { loading, error, data } = useAllPropertiesQuery();

  const properties: PropertyListFragment[] = useMemo(
    () => data?.properties?.getAll?.filter(Boolean) ?? [],
    [data],
  );

  // TODO: pass in sub path?
  const setCurrentProperty = useCallback(
    (pId: string) => {
      const property = properties.find((p) => p.id === pId);
      if (!property) {
        return;
      }
      setLastViewedProperty(property.id);
      const query = loadOptions(property.id);
      navigate(`/property/${property.id}${query}`);
    },
    [loadOptions, navigate, properties],
  );

  return {
    properties,
    setCurrentProperty,
    loading,
    error,
  };
}
