import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  variants: {
    faded: {
      color: 'primary.700',
      bg: 'primary.50',
      _hover: { bg: 'primary.100', color: 'primary.800' },
    },
    primary: {
      color: 'white',
      bg: 'primary.600',
      shadow: 'xs',
      _hover: { bg: 'primary.700', color: 'gray.200' },
    },
    // TODO: get rid of unused variants
    active: {
      color: 'white',
      bg: 'primary.600',
      _hover: { bg: 'primary.800', color: 'gray.400' },
    },
    nonActive: {
      color: 'white',
      bg: 'gray.700',
      _hover: { bg: 'gray.800', color: 'gray.400' },
    },
    clear: {
      _hover: {
        color: 'gray.500',
      },
    },
    outline: {
      borderColor: 'gray.300',
      borderRadius: 'lg',
      boxShadow: 'xs',
    },
  },
});
