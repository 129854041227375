import { gql } from '@apollo/client';
import { usePropertyFetchQuery } from '@src/graphql';
import { useParams } from 'react-router-dom';

export const PropertyDocument = gql(`
	query PropertyFetch($propertyId: Guid!) {
    properties {
			get(propertyId: $propertyId) {
				id
				subscriptionId
				name
				host
				timezone
				firstSentDataOn
				additionalHosts
				defaultApiToken
			}
		}
	}
`);

export const PropertyFirstSentDocument = gql(`
	query PropertyFirstSent($propertyId: Guid!) {
    properties {
			get(propertyId: $propertyId) {
				id
				firstSentDataOn
			}
		}
	}
`);

export type Property = NonNullable<
  NonNullable<ReturnType<typeof usePropertyFetchQuery>['data']>['properties']
>['get'];

type UsePropertyReturn = ReturnType<typeof usePropertyFetchQuery> & {
  property: Property | undefined;
};

export function useProperty(): UsePropertyReturn {
  const { propertyId } = useParams();

  const res = usePropertyFetchQuery({
    variables: {
      propertyId: propertyId!,
    },
    skip: !propertyId,
  });

  const property = res.data?.properties?.get;
  return { ...res, property };
}
