import { ApolloError } from '@apollo/client';
import { FullScreen } from '@components/Layout';
import { ErrorScreen } from '@components/Signup/ErrorScreen';
import { CheckEmail } from '@routes/SignUp/CheckEmail';
import { useResendVerificationEmail } from '@src/auth/useRegister';
import { useVerifyEmail } from '@src/auth/useVerifyEmail';
import { useEffectOnlyOnce } from '@src/hooks/useEffectOnlyOnce';
import { extractErrorMsg } from '@src/utils/error';
import { getNotifyState } from '@src/utils/notify';
import { FullScreenSpinner } from '@ui/Spinner';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

const VerifyEmail: FC = () => {
  const [{ code, userId }] = useQueryParams({
    code: StringParam,
    userId: StringParam,
  });
  const [verify, verifyResult] = useVerifyEmail();
  const [resendVerification, resendResult] = useResendVerificationEmail();

  useEffectOnlyOnce(() => {
    if (code && userId) {
      verify({ code, userId }).catch(console.error);
    }
  }, [code, userId, verify]);

  if (!code || !userId) {
    return (
      <ErrorScreen
        title="Sorry, there has been an issue."
        description="The link you clicked on is invalid. Please try again."
      />
    );
  }
  if (verifyResult.called) {
    return <Navigate to="/login" state={getNotifyState('email_verified')} />;
  }
  if (resendResult.called) {
    return <CheckEmail userId={userId} />;
  }

  if (verifyResult.error) {
    const isInvalidCode =
      verifyResult.error instanceof ApolloError &&
      verifyResult.error.graphQLErrors.find(
        (e) => e.extensions?.code === 'INVALID_CODE',
      );

    return (
      <ErrorScreen
        loading={verifyResult.loading}
        title={
          isInvalidCode
            ? 'Verification link expired'
            : 'Sorry, there has been an issue.'
        }
        description={
          isInvalidCode
            ? "You'll need to request a new verification link for this email address."
            : extractErrorMsg(verifyResult.error)
        }
        retry={() => {
          resendVerification(userId).catch(console.error);
        }}
        retryTitle="Click to resend"
        error={resendResult.error}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        clearAlert={resendResult.reset}
      />
    );
  }

  return (
    <FullScreen>
      <FullScreenSpinner />
    </FullScreen>
  );
};

export default VerifyEmail;
