import { Link, LinkProps, Text, Tooltip, WrapItem } from '@chakra-ui/react';
import { IconComponent } from '@ui/Icon';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

type NavItemButtonProps = {
  path?: string;
  Icon: IconComponent;
  label: string;
  isExpanded: boolean;
} & LinkProps;

export const NavItemButton: FC<NavItemButtonProps> = ({
  Icon,
  label,
  isExpanded,
  path,
  ...props
}) => {
  return (
    <WrapItem
      justifyContent="flex-start"
      alignItems="stretch"
      h="48px"
      w="full"
    >
      <Tooltip label={label} aria-label={label} isDisabled={isExpanded}>
        <Link
          pos="relative"
          {...props}
          as={path ? NavLink : undefined}
          to={path}
          display="flex"
          gap={3}
          alignItems="center"
          w="full"
          pl={3}
          aria-label={label}
          _activeLink={{ bg: 'primary.600' }}
          borderRadius="md"
          _hover={{
            textDecoration: 'none',
            bg: 'gray.700',
          }}
          color="gray.100"
        >
          <Icon height={24} width={24} />

          <Text
            pos="absolute"
            left="48px"
            fontSize="md"
            fontWeight="600"
            opacity={isExpanded ? '1' : '0'}
            transition="opacity 0.2s ease-in-out"
          >
            {label}
          </Text>
        </Link>
      </Tooltip>
    </WrapItem>
  );
};
