import { useCallback } from 'react';

type UsePersistOptionsReturn = {
  saveOptions: (propertyId: string | undefined) => void;
  clearOptions: (propertyId: string) => void;
  loadOptions: (propertyId: string) => string;
};

const getPersistOptionsKey = (propertyId: string) => `persisted_${propertyId}`;

// TODO: why are these in a hook?
export function usePersistOptions(): UsePersistOptionsReturn {
  const clearOptions = useCallback((propertyId: string) => {
    localStorage.removeItem(getPersistOptionsKey(propertyId));
  }, []);

  const loadOptions = useCallback((propertyId: string): string => {
    return localStorage.getItem(getPersistOptionsKey(propertyId)) ?? '';
  }, []);

  const saveOptions = useCallback((propertyId: string | undefined) => {
    if (!propertyId) return;
    localStorage.setItem(
      getPersistOptionsKey(propertyId),
      window.location.search,
    );
  }, []);

  return {
    saveOptions,
    clearOptions,
    loadOptions,
  };
}
