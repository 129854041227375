import { Flex, VStack } from '@chakra-ui/react';
import CircleLeft from '@icons/arrow-circle-left.svg?react';
import CircleRight from '@icons/arrow-circle-right.svg?react';
import DashboardIcon from '@icons/bar-chart-square-02.svg?react';
import FunnelIcon from '@icons/filter-funnel-01.svg?react';
import LifeBuoy from '@icons/life-buoy-01.svg?react';
import Settings from '@icons/settings-01.svg?react';
import { useProperty } from '@src/hooks/useProperty';
import { NavItemButton } from '@ui/NavItemButton';
import { FC, useState } from 'react';

import { Logo } from './Logo';
import { PersonalInfo } from './PersonalInfo';

// type SidebarNavProps = {};

export const SidebarNav: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { property } = useProperty();

  return (
    <Flex
      as="nav"
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      py={6}
      px={4}
      w={isExpanded ? '360px' : 20}
      transition="width 0.2s ease-in-out"
      color="black"
      bg="flamingo.blue"
      borderRight="1px"
      borderColor="gray.700"
    >
      <VStack as="ul" gap={1} alignItems="flex-start" w="full">
        <Logo isExpanded={isExpanded} />

        <NavItemButton
          label="Dashboard"
          Icon={DashboardIcon}
          path={property ? `/property/${property.id}` : '/property'}
          isExpanded={isExpanded}
        />
        <NavItemButton
          label="Funnels"
          Icon={FunnelIcon}
          // TODO: can I fix up the no property path?
          path={property ? `/funnels/${property.id}` : '/funnels'}
          isExpanded={isExpanded}
        />
      </VStack>

      <VStack as="ul" gap={2} alignItems="flex-start" w="full">
        <NavItemButton
          label="Help"
          Icon={LifeBuoy}
          href="https://www.flamingohq.com/help-center"
          isExpanded={isExpanded}
          target="_blank"
        />
        <NavItemButton
          label="Team settings"
          path="/team/settings"
          Icon={Settings}
          isExpanded={isExpanded}
        />

        <PersonalInfo isExpanded={isExpanded} />

        <NavItemButton
          isExpanded={isExpanded}
          label={isExpanded ? 'Collapse' : 'Expand'}
          Icon={isExpanded ? CircleLeft : CircleRight}
          onClick={() => setIsExpanded((e) => !e)}
        />
      </VStack>
    </Flex>
  );
};
