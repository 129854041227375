import {
  Button,
  ButtonProps,
  Heading,
  HStack,
  Image as ChakraImage,
  Link,
} from '@chakra-ui/react';
import ArrowLeftIcon from '@icons/arrow-left.svg?react';
import GoogleIcon from '@src/assets/images/google.svg?react';
import logoImage from '@src/assets/images/logo.png';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const Logo: FC = () => (
  <Link
    href="https://www.flamingohq.com"
    _hover={{
      textDecoration: 'none',
    }}
  >
    <HStack>
      <ChakraImage
        h={{ base: '48px', md: '32px' }}
        w={{ base: '48px', md: '32px' }}
        src={logoImage}
      />
      <Heading display={{ base: 'none', md: 'inline' }} size="md">
        Flamingo
      </Heading>
    </HStack>
  </Link>
);

export const GoogleButton: FC<ButtonProps> = ({ children, ...props }) => (
  <Button {...props} leftIcon={<GoogleIcon />} variant="outline">
    {children}
  </Button>
);

export const LoginLink: FC = () => (
  <Link
    as={RouterLink}
    to="/login"
    fontSize="sm"
    color="gray.600"
    fontWeight="600"
    mb={4}
  >
    <ArrowLeftIcon
      width="20px"
      height="20px"
      style={{
        display: 'inline-block',
        verticalAlign: 'bottom',
      }}
    />{' '}
    Back to login
  </Link>
);
