import { Box, Flex, Hide, Show } from '@chakra-ui/react';
import { SidebarNav } from '@components/SidebarNav';
import { MobileHeader } from '@components/SidebarNav/MobileHeader';
import { FC, ReactNode } from 'react';

type WithSidebarProps = {
  children: ReactNode;
};

export const WithSidebar: FC<WithSidebarProps> = ({ children }) => {
  return (
    <Flex h="100vh" flexDir={{ base: 'column', md: 'row' }} pos="relative">
      <Hide below="md">
        <SidebarNav />
      </Hide>
      <Show below="md">
        <MobileHeader />
      </Show>

      <Box as="main" w="full" overflow="auto" position="relative">
        {children}
      </Box>
    </Flex>
  );
};
