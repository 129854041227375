import { gql } from '@apollo/client';
import { Input_VerifyEmailModel, useVerifyEmailMutation } from '@src/graphql';
import { useCallback } from 'react';

import { authClient } from './api';
import { useNavigateToAppIfLoggedIn } from './useNavigateToAppIfLoggedIn';

export const VerifyEmailMutationDocument = gql(`
	mutation VerifyEmail(
		$model: Input_VerifyEmailModel!
  ) {
		account {
			verifyEmail(model: $model) {
        refreshToken
        refreshTokenExpiry
        token
      }
		}
	}
`);

type UseVerifyEmailReturnValue = [
  (verifyDetails: Input_VerifyEmailModel) => Promise<void>,
  ReturnType<typeof useVerifyEmailMutation>[1],
];

export function useVerifyEmail(): UseVerifyEmailReturnValue {
  useNavigateToAppIfLoggedIn();
  const [postVerify, verifyResult] = useVerifyEmailMutation({
    client: authClient,
  });

  const verifyEmail = useCallback(
    async (verifyDetails: Input_VerifyEmailModel) => {
      await postVerify({
        variables: {
          model: verifyDetails,
        },
      });
    },
    [postVerify],
  );

  return [verifyEmail, verifyResult];
}
