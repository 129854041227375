import { Flex, Spinner } from '@chakra-ui/react';
import { FC } from 'react';

export const FullScreenSpinner: FC = () => (
  <Flex
    justifyContent="center"
    alignItems="center"
    top={0}
    bottom={0}
    left={0}
    right={0}
    position="absolute"
    bg="rgba(255,255,255,0.8)"
  >
    <Spinner size="xl" color="primary.500" />
  </Flex>
);
