import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const inputHelpers = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const Input = inputHelpers.defineMultiStyleConfig({
  baseStyle: {
    addon: {
      _readOnly: {
        color: 'gray.300',
      },
    },
    element: {
      _readOnly: {
        color: 'gray.300',
      },
    },
    field: {
      _readOnly: {
        backgroundColor: 'gray.50',
        color: 'gray.300',
        borderColor: 'gray.300',
      },
    },
  },
});
