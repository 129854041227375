import { proxy } from 'valtio';

import { TokenDetails } from './api';
import { clearStorage, setAccessToken } from './localStorageAuth';
import { getIsRefreshExpired } from './refreshToken';

type AuthState = {
  isLoading: boolean;
  isValid: boolean;
};

export const authStore = proxy<AuthState>({
  isLoading: true,
  isValid: !getIsRefreshExpired(),
});

export const actions = {
  login: (token: TokenDetails, rememberMe?: boolean): void => {
    setAccessToken(token, rememberMe);
    authStore.isLoading = false;
    authStore.isValid = true;
  },
  logout: (): void => {
    authStore.isLoading = false;
    authStore.isValid = false;
    clearStorage();
    window.location.replace('/login');
  },
  tokenRefreshed: (token: TokenDetails): void => {
    actions.login(token);
  },
  tokenRefreshing: (): void => {
    authStore.isLoading = true;
  },
};
