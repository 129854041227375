import { HStack, Image as ChakraImage, WrapItem } from '@chakra-ui/react';
import logoImage from '@src/assets/images/logo.svg';
import logoTextImage from '@src/assets/images/logo-text.svg';
import { FC } from 'react';

type LogoProps = {
  isExpanded: boolean;
};

export const Logo: FC<LogoProps> = ({ isExpanded }) => (
  <WrapItem w="full" mb={8}>
    <HStack pl={2} pos="relative">
      <ChakraImage src={logoImage} w={10} h={10} />

      <HStack
        pos="absolute"
        w="125px"
        left="44px"
        opacity={isExpanded ? '1' : '0'}
        transition="opacity 0.2s ease-in-out"
        alignItems="baseline"
      >
        <ChakraImage src={logoTextImage} w={125} h={10} />
      </HStack>
    </HStack>
  </WrapItem>
);
