import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
  WrapItem,
} from '@chakra-ui/react';
// import ReferralIcon from '@icons/container.svg?react';
import LogoutIcon from '@icons/log-out-01.svg?react';
// import SlackIcon from '@icons/message-smile-circle.svg?react';
import SettingsIcon from '@icons/settings-01.svg?react';
import { useLogout } from '@src/auth/useLogout';
import { useUserProfile } from '@src/hooks/useUserProfile';
import { UserAvatar } from '@ui/UserAvatar';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

type PersonalInfoProps = {
  isExpanded: boolean;
  onSelect?: () => void;
};

export const PersonalInfo: FC<PersonalInfoProps> = ({
  isExpanded,
  onSelect,
}) => {
  const { userProfile } = useUserProfile();
  const logout = useLogout();
  return (
    <WrapItem w="full" flexDirection="column">
      <HStack
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor={isExpanded ? 'gray.600' : 'transparent'}
        width="full"
        my={6}
        py={6}
        justifyContent="space-between"
        pos="relative"
      >
        <HStack gap={3} ml={1}>
          <Menu>
            <MenuButton margin="1px" pos="relative">
              <UserAvatar user={userProfile!} />
            </MenuButton>

            <MenuList color="gray.700" fontWeight="500" fontSize="sm">
              <Flex p={1} mr={1} gap={3}>
                <Box pos="relative">
                  <UserAvatar user={userProfile!} />
                </Box>
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight="600">{`${userProfile?.firstName} ${userProfile?.lastName}`}</Text>
                  <Text color="gray.600" fontWeight="400">
                    {userProfile?.email}
                  </Text>
                </VStack>
              </Flex>

              <MenuDivider />
              <MenuItem
                icon={<SettingsIcon width="16px" height="16px" />}
                as={NavLink}
                to="/user/settings"
                onClick={onSelect}
              >
                Personal Settings
              </MenuItem>
              <MenuDivider />
              {/* <MenuItem icon={<SlackIcon width="16px" height="16px" />}>
                Slack Community
              </MenuItem>
              <MenuItem icon={<ReferralIcon width="16px" height="16px" />}>
                Referral Program
              </MenuItem>  */}
              {/* <MenuDivider /> */}
              <MenuItem
                icon={<LogoutIcon width="16px" height="16px" />}
                onClick={logout}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Menu>

          <VStack alignItems="flex-start" gap={0} pos="absolute" left="56px">
            <Text
              color="white"
              fontSize="sm"
              fontWeight="500"
              width="160px"
              opacity={isExpanded ? '1' : '0'}
              transition="opacity 0.2s ease-in-out"
            >
              {userProfile?.firstName} {userProfile?.lastName}
            </Text>

            <Text
              as={NavLink}
              color="gray.100"
              fontSize="sm"
              fontWeight="600"
              opacity={isExpanded ? '1' : '0'}
              transition="opacity 0.2s ease-in-out"
              to="/user/settings"
            >
              Personal Settings
            </Text>
          </VStack>
        </HStack>

        <Tooltip label="Log out" aria-label="Log out" isDisabled={!isExpanded}>
          <IconButton
            opacity={isExpanded ? '1' : '0'}
            transition="opacity 0.02s ease-in-out"
            transitionDelay={isExpanded ? '0.2s' : '0s'}
            pointerEvents={isExpanded ? 'auto' : 'none'}
            onClick={logout}
            pos="absolute"
            left="calc(100% - 36px)"
            aria-label="Logout"
            variant="ghost"
            _hover={{
              bg: 'gray.700',
            }}
            color="gray.100"
            icon={<LogoutIcon width="16px" height="16px" />}
            justifySelf="flex-end"
          ></IconButton>
        </Tooltip>
      </HStack>
    </WrapItem>
  );
};
