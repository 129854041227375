import { AlertProps } from '@ui/Alert/Alert';

export type NotifyCode = 'email_verified';

type Status = AlertProps['status'];

type AlertDetails = {
  title: string;
  description?: string;
  status: Status;
};

export function getAlertDetails(code: NotifyCode): AlertDetails {
  switch (code) {
    case 'email_verified':
      return {
        title: 'Your email was verified. Login to continue.',
        status: 'success',
      };

    default:
      return {
        title: 'Error',
        description: 'An unknown issue occurred. Please try again.',
        status: 'error',
      };
  }
}

export function getNotifyState(code: NotifyCode): object {
  return {
    notify: code,
  };
}
