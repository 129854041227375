import { Flex, IconButton, VStack } from '@chakra-ui/react';
import DashboardIcon from '@icons/bar-chart-square-02.svg?react';
import FunnelIcon from '@icons/filter-funnel-01.svg?react';
import LifeBuoy from '@icons/life-buoy-01.svg?react';
import MenuIcon from '@icons/menu-02.svg?react';
import Settings from '@icons/settings-01.svg?react';
import CloseIcon from '@icons/x-close.svg?react';
import { useProperty } from '@src/hooks/useProperty';
import { NavItemButton } from '@ui/NavItemButton';
import { FC, useState } from 'react';

import { Logo } from './Logo';
import { PersonalInfo } from './PersonalInfo';

export const MobileHeader: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Flex as="header" py={3} pl={4} pr={2} bg="flamingo.blue">
        <Logo isExpanded />
        <IconButton
          variant="link"
          aria-label="menu"
          icon={isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          color="primary.100"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      </Flex>
      {isMenuOpen && <Nav onHide={() => setIsMenuOpen(false)} />}
    </>
  );
};

type NavProps = {
  onHide: () => void;
};

// TODO: can I re-use SidebarNav for this?
const Nav: FC<NavProps> = ({ onHide }) => {
  const { property } = useProperty();
  return (
    <Flex
      as="nav"
      pos="absolute"
      direction="column"
      top="56px"
      left={0}
      right={0}
      bottom={0}
      justifyContent="space-between"
      zIndex={100}
      alignItems="flex-start"
      py={6}
      px="14px"
      transition="width 0.2s ease-in-out"
      color="black"
      bg="flamingo.blue"
      borderRight="1px"
      borderColor="gray.700"
    >
      <VStack as="ul" gap={5} alignItems="flex-start" w="full">
        <NavItemButton
          label="Dashboard"
          Icon={DashboardIcon}
          path={property ? `/property/${property.id}` : '/property'}
          onClick={onHide}
          isExpanded
        />
        <NavItemButton
          label="Funnels"
          Icon={FunnelIcon}
          onClick={onHide}
          // TODO: can I fix up the no property path?
          path={property ? `/funnels/${property.id}` : '/funnels'}
          isExpanded
        />
      </VStack>

      <VStack as="ul" gap={2} alignItems="flex-start" w="full">
        <NavItemButton
          label="Help"
          Icon={LifeBuoy}
          href="https://www.flamingohq.com/help-center"
          isExpanded
          onClick={onHide}
          target="_blank"
        />
        <NavItemButton
          label="Team settings"
          path="/team/settings"
          Icon={Settings}
          onClick={onHide}
          isExpanded
        />

        <PersonalInfo isExpanded onSelect={onHide} />
      </VStack>
    </Flex>
  );
};
