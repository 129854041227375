import { gql } from '@apollo/client';
import { useLogoutMutation } from '@src/graphql';
import { useCallback } from 'react';

import { actions } from './authStore';

export const LogoutMutationDocument = gql(`
	mutation Logout {
		account {
			logout 
		}
	}
`);

type UseLogoutReturnValue = () => Promise<void>;

export function useLogout(): UseLogoutReturnValue {
  const [postLogout] = useLogoutMutation();

  const logout = useCallback(async () => {
    try {
      await postLogout();
    } catch {
      // Ignoring errors, logout anyway
    }
    actions.logout();
  }, [postLogout]);

  return logout;
}
