import { Flex, useToken } from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';

type FeaturedIconProps = {
  icon: FC<ComponentProps<'svg'>>;
  colour: 'primary' | 'success' | 'error';
  size?: number;
};

export const FeaturedIcon: FC<FeaturedIconProps> = ({
  icon: Icon,
  colour,
  size = 60,
}) => {
  const [innerColor, outerColor, iconColor] = useToken('colors', [
    `${colour}.100`,
    `${colour}.50`,
    `${colour}.600`,
  ]);
  return (
    <Flex
      w={`${size}px`}
      h={`${size}px`}
      borderRadius={99999}
      bg={`radial-gradient(circle, ${innerColor} 0%, ${innerColor} 50%, ${outerColor} 50%, ${outerColor} 100%)`}
      justifyContent="center"
      alignItems="center"
    >
      <Icon
        height={`${size / 2}px`}
        // scale={size / 60}
        color={iconColor}
      />
    </Flex>
  );
};
