export type JwtPayload = {
  nbf: number;
  subid: string;
  email: string;
  nameid: string;
  exp: number;
  iat: number;
  iss: string;
};

export function parseJwt(token: string): JwtPayload {
  const base64Url = token.split('.')[1];
  if (!base64Url) throw new Error('Invalid JWT token');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload) as JwtPayload;
}

export function isJwtExpired(accessTokenPayload: JwtPayload): boolean {
  return !accessTokenPayload.exp
    ? false
    : Date.now() >= accessTokenPayload.exp * 1000 - 5000;
}
