import { Box, Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type FullScreenProps = {
  children: ReactNode;
};

export const FullScreen: FC<FullScreenProps> = ({ children }) => {
  return (
    <Flex flex="1">
      <Box as="main" w="full" overflow="auto" position="relative">
        {children}
      </Box>
    </Flex>
  );
};
