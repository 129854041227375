import { useSnapshot } from 'valtio';

import { authStore } from './authStore';

type UseAuthReturnValue = {
  isValid: boolean | undefined;
  isLoading: boolean;
};

export function useAuth(): UseAuthReturnValue {
  const { isLoading, isValid } = useSnapshot(authStore);
  return {
    isValid,
    isLoading,
  };
}
