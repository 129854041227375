import { Avatar, Box } from '@chakra-ui/react';
import { FC } from 'react';

type ProfileUser = {
  firstName?: string | null;
  lastName?: string | null;
  profileUrl?: string | null;
};

type UserAvatarProps = {
  user: ProfileUser;
  size?: number;
};

export const UserAvatar: FC<UserAvatarProps> = ({ user, size = 10 }) => {
  return (
    <Box pos="relative">
      <Avatar
        pos="absolute"
        right={0}
        name={`${user?.firstName} ${user?.lastName}`}
        height={size}
        width={size}
        size="sm"
        fontWeight={900}
        letterSpacing={2}
        // Text centering fix
        pl={'1px'}
      />
      <Avatar
        name={`${user?.firstName} ${user?.lastName}`}
        src={user?.profileUrl ?? undefined}
        height={size}
        width={size}
        size="sm"
      />
    </Box>
  );
};
