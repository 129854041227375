/// <reference types="vite/client" />

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import ErrorPage from '@routes/ErrorPage';
import * as Sentry from '@sentry/react';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { getApolloClient } from './apolloClient';
import { App } from './App';
import { theme } from './theme/theme';

Sentry.init({
  dsn: 'https://18cb82169326415d84c181896872e0b5@o1133952.ingest.sentry.io/4505225187622912',
  enabled: import.meta.env.PROD,
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={false}>
      <Suspense
        fallback={
          <Flex h="100vh" justifyContent="center" alignItems="center">
            <Spinner size="xl" color="primary.500" />
          </Flex>
        }
      >
        <ApolloProvider client={getApolloClient()}>
          <ChakraProvider theme={theme}>
            <BrowserRouter>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                // options={{ removeDefaultsFromUrl: true }}
              >
                <App />
              </QueryParamProvider>
            </BrowserRouter>
          </ChakraProvider>
        </ApolloProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
