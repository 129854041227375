import { extendTheme } from '@chakra-ui/react';

import { Button } from './button';
import { Checkbox } from './checkbox';
import { colors } from './colours';
import { Input } from './input';
import { tabsTheme } from './tabs';

export const theme = extendTheme({
  colors,
  shadows: {
    xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },
  fonts: {
    body: 'PlusJakartaSans, system-ui, sans-serif',
    heading: 'PlusJakartaSans, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  components: {
    Form: {
      baseStyle: {
        requiredIndicator: {
          color: 'gray.900',
        },
      },
    },
    Checkbox,
    Input,
    Button,
    Card: {
      baseStyle: {
        // container: {
        //   border: '1px solid',
        //   borderColor: 'gray.200',
        //   shadow: 'sm',
        // },
        body: {
          padding: 6,
        },
        footer: {
          px: 6,
          py: 4,
        },
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'primary.600',
        },
        track: {
          bg: 'gray.200',
        },
      },
    },
    Tabs: tabsTheme,
  },
});
