import { fetchRefreshToken, tokenDetailsSchema } from './api';
import { actions } from './authStore';
import { isJwtExpired, parseJwt } from './jwt';
import { getAccessToken } from './localStorageAuth';

let refreshTokenPromise: Promise<string> | null = null;

// Error's will cause user to be logged out.
export async function getToken(): Promise<string | null> {
  if (refreshTokenPromise) {
    const token = await refreshTokenPromise;
    return token;
  }

  const accessToken = getAccessToken();
  try {
    if (!accessToken) throw new Error('No access token found');
    const isRefreshExpired = expiresInMs(accessToken.refreshTokenExpiry) < 5000;
    if (isRefreshExpired) throw new Error('Refresh token expired');

    // Are we valid still?
    const oldJwtPayload = parseJwt(accessToken.token);
    if (!isJwtExpired(oldJwtPayload)) {
      return accessToken.token;
    }
  } catch (error) {
    console.log(error, '<<<<< ERROR getting token');
    actions.logout();
    return null;
  }

  const { promise, resolve, reject } = Promise.withResolvers<string>();
  refreshTokenPromise = promise;
  try {
    actions.tokenRefreshing();
    const authToken = await fetchRefreshToken({
      jwtToken: accessToken.token,
      refreshToken: accessToken.refreshToken,
    });
    const validatedToken = tokenDetailsSchema.parse(authToken);
    actions.tokenRefreshed(validatedToken);
    resolve(validatedToken.token);
    return validatedToken.token;
  } catch (error) {
    console.log(error, '<<<<< ERROR refreshing token');
    actions.logout();
    reject(null);
    return null;
  }
}

function expiresInMs(tokenExpiry: string | number): number {
  const tokenExpired = new Date(tokenExpiry).getTime() - Date.now();
  return tokenExpired;
}

export function getIsRefreshExpired(): boolean {
  const accessToken = getAccessToken();
  if (!accessToken) return true;
  const expired = expiresInMs(accessToken.refreshTokenExpiry) < 5000;
  return expired;
}
