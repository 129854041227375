import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const checkBoxHelpers = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const sizes = {
  sm: checkBoxHelpers.definePartsStyle({
    control: defineStyle({
      boxSize: 4,
    }),
    label: defineStyle({
      fontSize: 'sm',
      marginLeft: 2,
    }),
  }),
};

export const Checkbox = checkBoxHelpers.defineMultiStyleConfig({
  sizes,
  baseStyle: {
    control: {
      _checked: {
        bg: 'primary.600',
        borderColor: 'primary.600',
        color: 'white',
        _hover: {
          bg: 'primary.700',
          borderColor: 'primary.700',
        },
      },
      _indeterminate: {
        bg: 'primary.600',
        borderColor: 'primary.600',
        color: 'white',
        _hover: {
          bg: 'primary.700',
          borderColor: 'primary.700',
        },
      },
      _disabled: {
        bg: 'gray.200',
        borderColor: 'gray.200',
        color: 'gray.500',
      },
      _focus: {
        boxShadow: 'outline',
      },
    },
  },
});
