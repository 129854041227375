import { gql } from '@apollo/client';
import {
  Input_CreateAccountModel,
  useRegisterMutation,
  useResendVerificationEmailMutation,
} from '@src/graphql';
import { useCallback, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { authClient } from './api';
import { useNavigateToAppIfLoggedIn } from './useNavigateToAppIfLoggedIn';

export const RegisterMutationDocument = gql(`
	mutation Register(
    $model: Input_CreateAccountModel!
  ) {
		account {
			register(model: $model)
		}
	}
`);

export const ResendVerificationEmailMutationDocument = gql(`
  mutation ResendVerificationEmail(
    $userId: Guid!
  ) {
    account {
      newEmailVerification(model: { userId: $userId })
    }
  }
`);

export type SubmittedUser = {
  email: string;
  userId: string;
};

type UseRegisterReturnValue = [
  (loginDetails: Input_CreateAccountModel) => Promise<void>,
  ReturnType<typeof useRegisterMutation>[1],
  SubmittedUser | undefined,
];

export function useRegister(): UseRegisterReturnValue {
  const [{ code, subscriptionId }] = useQueryParams({
    code: StringParam,
    subscriptionId: StringParam,
  });
  useNavigateToAppIfLoggedIn();
  const [submittedUser, setSubmittedUser] = useState<{
    email: string;
    userId: string;
  }>();
  const [postRegister, registerResult] = useRegisterMutation({
    client: authClient,
  });

  const register = useCallback(
    async (registrationDetails: Input_CreateAccountModel) => {
      const res = await postRegister({
        variables: {
          model: {
            ...registrationDetails,
            invitationCode: code,
            invitationSubscriptionId: subscriptionId,
          },
        },
      });

      if (!res.data?.account?.register) {
        throw new Error('Registration failed');
      }

      setSubmittedUser({
        email: registrationDetails.email,
        userId: res.data?.account?.register,
      });
    },
    [postRegister, code, subscriptionId],
  );

  return [register, registerResult, submittedUser];
}

type UseResendVerificationEmailReturnValue = [
  (userId: string) => Promise<void>,
  ReturnType<typeof useResendVerificationEmailMutation>[1],
];

export function useResendVerificationEmail(): UseResendVerificationEmailReturnValue {
  useNavigateToAppIfLoggedIn();
  const [postResendVerificationEmail, resendVerificationEmailResult] =
    useResendVerificationEmailMutation({
      client: authClient,
    });

  const resendVerificationEmail = useCallback(
    async (userId: string) => {
      await postResendVerificationEmail({
        variables: {
          userId,
        },
      });
    },
    [postResendVerificationEmail],
  );

  return [resendVerificationEmail, resendVerificationEmailResult];
}
