import { TokenDetails, tokenDetailsSchema } from './api';

const ACCESS_TOKEN_KEY = 'access_token_1';
const LAST_LOGGED_USERID_KEY = 'user_id';
const LAST_VIEWED_PROPERTY = 'last_viewed_property';

export function setLastLoggedInUserId(userId: string): void {
  localStorage.setItem(LAST_LOGGED_USERID_KEY, userId);
}

export function getLastLoggedInUserId(): string | null {
  return localStorage.getItem(LAST_LOGGED_USERID_KEY);
}

let rememberMe: boolean | null = null;

export function setAccessToken(
  tokenDetails: TokenDetails,
  forceRemember?: boolean,
): void {
  if (forceRemember) {
    rememberMe = true;
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (rememberMe || forceRemember) {
    localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(tokenDetails));
    return;
  }
  sessionStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(tokenDetails));
}

export function getAccessToken(): TokenDetails | null {
  if (rememberMe === null) {
    rememberMe = !!localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  const tokenString = rememberMe
    ? localStorage.getItem(ACCESS_TOKEN_KEY)
    : sessionStorage.getItem(ACCESS_TOKEN_KEY);

  if (!tokenString) return null;
  try {
    const token = JSON.parse(tokenString);
    const validatedToken = tokenDetailsSchema.parse(token);
    return validatedToken;
  } catch {
    return null;
  }
}

export function replaceAuthToken(newToken: string): void {
  const token = getAccessToken();
  if (!token) return;
  setAccessToken({ ...token, token: newToken });
}

export function setLastViewedProperty(propertyId: string): void {
  if (rememberMe) {
    localStorage.setItem(LAST_VIEWED_PROPERTY, propertyId);
    return;
  }
  sessionStorage.setItem(LAST_VIEWED_PROPERTY, propertyId);
}

export function getLastViewedProperty(): string | null {
  if (rememberMe) {
    return localStorage.getItem(LAST_VIEWED_PROPERTY);
  }
  return sessionStorage.getItem(LAST_VIEWED_PROPERTY);
}

export function clearLastViewedProperty(): void {
  if (rememberMe) {
    localStorage.removeItem(LAST_VIEWED_PROPERTY);
    return;
  }
  sessionStorage.removeItem(LAST_VIEWED_PROPERTY);
}

export function clearStorage(): void {
  localStorage.clear();
  sessionStorage.clear();
}
