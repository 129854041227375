import { useEffect } from 'react';
import {
  // useLocation,
  useNavigate,
} from 'react-router-dom';

import { useAuth } from './useAuth';

export function useNavigateToAppIfLoggedIn(): void {
  const auth = useAuth();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  // const location = useLocation();
  // const from = location.state?.from?.pathname || '/';
  const from = '/';
  useEffect(() => {
    if (auth.isValid) {
      navigate(from, { replace: true });
    }
  }, [auth.isValid, from, navigate]);
}
