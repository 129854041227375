import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { getHttpLink } from '@src/apolloClient';
import {
  Input_RefreshTokenModel,
  LoginMutation,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '@src/graphql';
import { z } from 'zod';

type OptionalTokenDetails = Omit<
  NonNullable<LoginMutation['account']>['login'],
  '__typename'
>;

export type TokenDetails = {
  [k in keyof Required<OptionalTokenDetails>]: Exclude<
    OptionalTokenDetails[k],
    null | undefined
  >;
};

export const tokenDetailsSchema = z.object({
  refreshToken: z.string().min(1),
  refreshTokenExpiry: z.string().min(1),
  token: z.string().min(1),
});

export const authClient = new ApolloClient({
  link: getHttpLink(),
  cache: new InMemoryCache({}),
  connectToDevTools: false,
});

export const RefreshTokenMutationDocument = gql(`
	mutation RefreshToken(
    $model: Input_RefreshTokenModel!
  ) {
		account {
			refreshToken(model: $model) {
        token
        refreshToken
        refreshTokenExpiry
      }
		}
	}
`);

type RefreshResult = NonNullable<
  RefreshTokenMutation['account']
>['refreshToken'];
export async function fetchRefreshToken(
  refreshDetails: Input_RefreshTokenModel,
): Promise<RefreshResult> {
  const result = await authClient.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: RefreshTokenMutationDocument,
    variables: {
      model: refreshDetails,
    },
  });

  if (result.data?.account?.refreshToken) {
    return result.data?.account?.refreshToken;
  }

  // TODO: catch errors
  throw new Error('Could not register');
}
