import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useEffectOnlyOnce = (
  callback: EffectCallback,
  dependencies?: DependencyList,
): void => {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }
    callback();
    calledOnce.current = true;
  }, [callback, dependencies]);
};
