import { ApolloError } from '@apollo/client';

export function extractErrorMsg(error: unknown): string {
  if (error instanceof ApolloError) {
    let msg = '';
    error.graphQLErrors.forEach((e) => {
      if (
        e.extensions?.code === 'BAD_REQUEST' ||
        e.extensions?.code === 'INTERNAL_SERVER_ERROR'
      ) {
        msg = 'Something unexpected happened.\n';
      }
      if (e.extensions?.code === 'ACCESS_DENIED') {
        msg = 'Sorry, you do not have access to this resource.\n';
      }
      if (e.extensions?.code === 'INVALID_CODE') {
        msg = 'Verification code has expired or is invalid.\n';
      }
      if (e.extensions?.code === 'DUPLICATE_REQUEST') {
        msg = 'Email already verified.\n';
      }
    });
    if (msg.length > 0) {
      return `${msg}Please try again.`;
    }
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }
  return 'An unknown issue occurred. Please try again.';
}
