import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const tabHelpers = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = tabHelpers.definePartsStyle({
  tab: {
    fontWeight: 'semibold',
    borderBottom: '2px solid',
    _selected: {
      color: 'primary.700',
      mb: '-1px',
    },
  },
  tablist: {
    color: 'gray.500',
    borderBottom: '1px solid',
    borderColor: 'inherit',
  },
});

const mainChartVariant = tabHelpers.definePartsStyle(() => {
  return {
    tab: {
      border: '2px solid',
      borderColor: 'transparent',
      borderBottom: 'none',

      _selected: {
        mb: '-2px',
      },
    },
    tablist: {
      borderBottom: 'none',
      color: 'text',
    },
    tabpanel: {
      paddingX: 0,
    },
  };
});

const borderedVariant = tabHelpers.definePartsStyle(() => {
  return {
    root: {
      border: '1px solid',
      borderColor: 'gray.200',
      borderRadius: 'xl',
      shadow: 'sm',
      overflow: 'hidden',
    },
    tablist: {
      alignItems: 'center',
      borderRadius: 'md',
      py: '10px',
      px: '22px',
      borderBottomRadius: 'none',
    },
    tab: {
      flex: 1,
      margin: '4px',
      border: 'none',
      color: 'gray.500',
      fontSize: 'lg',
      py: '10px',
      px: '14px',
      _selected: {
        marginBottom: '4px',
        bgColor: 'gray.100',
        borderRadius: 'md',
        color: 'gray.700',
      },
    },
  };
});

const buttonGroupVariant = tabHelpers.definePartsStyle(() => {
  return {
    root: {},
    tablist: {
      width: 'fit-content',
      border: '1px solid',
      outline: 'none',
      borderRadius: 'lg',
      shadow: 'xs',
      overflow: 'hidden',
      borderColor: 'gray.300 !important',
    },
    tab: {
      fontSize: 'sm',
      color: 'gray.700',
      borderColor: 'gray.300 !important',
      border: 'none',
      borderLeft: '1px solid',
      _first: {
        borderLeft: 'none',
      },
      _selected: {
        color: 'gray.800',
        border: 'none',
        borderLeft: '1px solid',
        mb: '0',
        bg: 'gray.100',
      },
    },
  };
});

const variants = {
  'main-chart': mainChartVariant,
  bordered: borderedVariant,
  'button-group': buttonGroupVariant,
};

// export the component theme
export const tabsTheme = tabHelpers.defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    colorScheme: 'primary',
  },
});
