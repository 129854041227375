import { gql } from '@apollo/client';
import { UserProfileFieldsFragment, useUserProfileQuery } from '@src/graphql';
import { useCallback } from 'react';

export const UserProfileQueryDocument = gql(`
  fragment UserProfileFields on UserProfileModel {
		id
		firstName,
		lastName,
		timezone,
		email,
		profileUrl
		subscriptions {
			id
			name
			role
			planType
			createdOn
		}
  }

	query UserProfile {
		profile {
			get(size: 40) {
				...UserProfileFields
			}
		}
	}
`);

type UseUserProfileReturn = {
  userProfile: UserProfileFieldsFragment | undefined;
  roleForSubscription: (subscriptionId: string) => string;
} & Omit<ReturnType<typeof useUserProfileQuery>, 'data'>;

export function useUserProfile(): UseUserProfileReturn {
  const { data, ...res } = useUserProfileQuery();

  const userProfile: UserProfileFieldsFragment | undefined = data?.profile?.get;

  const roleForSubscription = useCallback(
    (subscriptionId: string): string => {
      const subscription = userProfile?.subscriptions
        ?.filter(Boolean)
        .find((sub) => sub.id === subscriptionId);
      return subscription?.role ?? 'none';
    },
    [userProfile],
  );

  return { userProfile, roleForSubscription, ...res };
}
