import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertProps as ChakraAlertProps,
  AlertTitle,
  IconButton,
  useToken,
  VStack,
} from '@chakra-ui/react';
import AlertCircle from '@icons/alert-circle.svg?react';
import AlertCheck from '@icons/check-circle-broken.svg?react';
import CloseIcon from '@icons/x-close.svg?react';
import { FC } from 'react';

export type AlertProps = {
  status: 'error' | 'success' | 'warning' | 'info';
  title: string;
  description?: string;
  onClose?: () => void;
  containerProps?: ChakraAlertProps;
};

export const Alert: FC<AlertProps> = ({
  title,
  description,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  status,
  onClose,
  containerProps = {},
}) => {
  const Icon = status === 'error' ? AlertCircle : AlertCheck;
  const colorTheme = status === 'info' ? 'primary' : status;
  const [outline] = useToken('colors', [`${colorTheme}.700`]);
  return (
    <ChakraAlert
      status={status}
      flexDirection="row"
      alignItems="flex-start"
      position="relative"
      borderRadius="md"
      border="1px solid"
      bg={`${colorTheme}.50`}
      borderColor={`${colorTheme}.700`}
      gap={4}
      {...containerProps}
    >
      <Icon
        width="16px"
        height="16px"
        color={outline}
        style={{ marginTop: '4px', flex: 'none' }}
      />

      <VStack alignItems="flex-start" gap={0}>
        <AlertTitle
          mr={2}
          color={`${colorTheme}.700`}
          fontSize="sm"
          fontWeight={600}
          whiteSpace="pre-line"
        >
          {title}
        </AlertTitle>

        {description && (
          <AlertDescription
            color={`${colorTheme}.700`}
            fontSize="sm"
            whiteSpace="pre-line"
          >
            {description}
          </AlertDescription>
        )}
      </VStack>

      {onClose && (
        <IconButton
          onClick={onClose}
          icon={<CloseIcon color="currentColor" width="16px" height="16px" />}
          mt="-10px"
          mr="-13px"
          variant="unstyled"
          alignSelf="flex-start"
          ml="auto"
          aria-label="Close alert"
          color={outline}
          _hover={{
            color: `${colorTheme}.900`,
          }}
        />
      )}
    </ChakraAlert>
  );
};
