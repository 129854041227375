import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { FullScreen } from '@components/Layout';
import BrokenLinkIcon from '@icons/link-broken-01.svg?react';
import { LoginLink } from '@routes/SignUp/components';
import { Alert } from '@ui/Alert';
import { FeaturedIcon } from '@ui/Icon';
import { FullScreenSpinner } from '@ui/Spinner';
import { FC } from 'react';

type ErrorScreenProps = {
  title: string;
  description: string;
  retry?: () => void;
  retryTitle?: string;
  error?: Error;
  clearAlert?: () => void;
  loading?: boolean;
};

export const ErrorScreen: FC<ErrorScreenProps> = ({
  title,
  description,
  retry,
  retryTitle,
  error,
  clearAlert,
  loading,
}) => {
  return (
    <FullScreen>
      {loading && <FullScreenSpinner />}
      <Flex
        justifyContent="center"
        mt={{ base: 12, md: '96px' }}
        mb={{ base: '60px', md: '96px' }}
        mx={2}
      >
        <Flex w="360px" flexDirection="column" alignItems="center">
          <FeaturedIcon icon={BrokenLinkIcon} colour="error" />

          <Heading size="lg" mb={4} textAlign="center">
            {title}
          </Heading>
          <Text textAlign="center" color="gray.600" mb={8}>
            {description}
          </Text>

          {retry && retryTitle && (
            <Button
              w="full"
              bg="primary.600"
              _hover={{
                bg: 'primary.700',
              }}
              colorScheme="primary"
              mb={4}
              onClick={retry}
            >
              {retryTitle}
            </Button>
          )}

          <LoginLink />

          {error && (
            <Alert
              containerProps={{ maxW: '400px' }}
              status="error"
              title="Error"
              description={error.message}
              onClose={clearAlert}
            />
          )}
        </Flex>
      </Flex>
    </FullScreen>
  );
};
