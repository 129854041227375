import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import ArrowLeftIcon from '@icons/arrow-left.svg?react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ErrorPage: FC = () => {
  return (
    <Flex
      alignItems="center"
      h="100vh"
      justifyContent={{
        base: 'center',
        md: 'flex-start',
      }}
    >
      <Flex
        flexDirection="column"
        m={4}
        ml={{
          base: undefined,
          md: '112px',
        }}
      >
        <Text color="primary.700" fontWeight={600} mb={3}>
          Error
        </Text>

        <Heading as="h1" size="3xl" mb={6}>
          Something has gone wrong!
        </Heading>

        <Text color="gray.600" fontSize="xl" mb={12}>
          Sorry about that. We&apos;ve logged this error and will fix it
          shortly.
        </Text>

        <Flex
          gap={3}
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
        >
          <Button as={RouterLink} variant="outline" size="lg" h="60px" to="/">
            <ArrowLeftIcon
              width="24px"
              height="24px"
              style={{
                display: 'inline-block',
                verticalAlign: 'bottom',
                marginRight: '8px',
              }}
            />
            Go back
          </Button>
          <Button
            as={Link}
            h="60px"
            href="https://www.flamingohq.com/help-center"
            target="_blank"
            colorScheme="primary"
            size="lg"
            bg="primary.600"
            _hover={{
              bg: 'primary.700',
            }}
          >
            Contact support
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ErrorPage;
