import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';

import { getToken } from './auth/refreshToken';
import { TypedTypePolicies } from './graphql';

export function getHttpLink(): ApolloLink {
  return createHttpLink({
    uri: 'https://api.flamingohq.app/graphql',
  });
}

function createAuthLink(): ApolloLink {
  return setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      // eslint-disable-next-line
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
}

const typePolicies: TypedTypePolicies = {
  Query_Lists: {
    keyFields: [],
    merge: false,
  },

  Query_Subscriptions: {
    keyFields: [],
    merge: false,
  },

  Query_Series: {
    keyFields: [],
    merge: false,
  },

  Query_Metrics: {
    keyFields: [],
    merge: false,
  },

  Query_Conversions: {
    keyFields: [],
    merge: false,
  },

  Query_Funnels: {
    keyFields: [],
    merge: false,
  },

  Query_Properties: {
    keyFields: false,
    merge: false,
    // fields: {
    //   getAll: {
    //     keyArgs: [],
    //     merge: false,
    //   },
    //   get: {
    //     keyArgs: ['$propertyId'],
    //     merge: false,
    //   },
    // },
  },

  // PropertyModel: {
  //   keyFields: ['id'],
  // },

  // PropertySummaryModel: {
  //   keyFields: ['id'],
  // },

  SubscriptionMemberModel: {
    keyFields: ['userId'],
  },

  Query: {
    fields: {
      series: {
        keyArgs: ['$propertyId', '$date'],
        merge: false,
      },
      lists: {
        keyArgs: ['$propertyId', '$date'],
        merge: false,
      },
      metrics: {
        keyArgs: ['$propertyId', '$date'],
        merge: false,
      },
      lookups: {
        keyArgs: ['$propertyId', '$date'],
        merge: false,
      },
      properties: {
        keyArgs: ['$propertyId'],
        merge: false,
      },
      // subscriptions: {
      //   keyArgs: ['$subscriptionId'],
      //   merge: false,
      // },
      //       keyArgs: (args, context) => {
      //         console.log(args, context);
      //         const listType =
      //           // @ts-ignore
      //           context.field?.selectionSet?.selections?.[0]?.name?.value;
      //         console.log(listType, '<<<< list type');
      //         return ['$propertyId', '$date', listType];
      //       },
      // },
    },
  },
};

const cache = new InMemoryCache({
  typePolicies,
});

let client: ApolloClient<NormalizedCacheObject>;

const retryLink = new RetryLink({
  attempts: {
    max: 3,
  },
}) as unknown as ApolloLink;

export function getApolloClient(): typeof client {
  if (!client) {
    const links = ApolloLink.from([retryLink, createAuthLink(), getHttpLink()]);

    client = new ApolloClient<NormalizedCacheObject>({
      link: links,
      cache,
      // NOTE: make sure cache updates are immutable or this fails!
      // assumeImmutableResults: true,
      // defaultOptions: {
      //   query: {
      //     canonizeResults: true,
      //   },
      // },
      connectToDevTools: process.env.NODE_ENV !== 'production',
    });
  }
  return client;
}
